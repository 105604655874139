
import { useI18n } from "vue-i18n/index";
import axios from "axios";
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import ApiRoutes from "@/core/config/ApiRoutes";

import jQuery from 'jquery';
import 'select2/dist/js/select2.full.min';
import 'select2/dist/css/select2.min.css';

export default defineComponent({
    name: "search-supplier-intra-modal",
    emit: ["refreshData"],
    props: {
        params: null,
        getParams: Function
    },
    components: {
        ErrorMessage,
        Field,
        Form,
    },
    setup(props, { emit }) {
        const { t, te } = useI18n();

        let statuses = ref<any>([]);

        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const searchProductOutputModalRef = ref<null | HTMLElement>(null);
        const initialValues = {
            status       : null,
            date         : null,
            employee_id  : null,
            knownEmployee: true,
            last_name    : "",
            first_name   : "",
            job          : "",
            note         : "",
            products_ids : [],
        };
        const queryParams = ref(props.params);
        Object.assign(queryParams.value, initialValues);

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const submit = (values, actions) => {

            if (!submitButtonRef.value) {
                return;
            }

            if (props.getParams) props.getParams(queryParams.value);

            hideModal(searchProductOutputModalRef.value);
        };

        const getStatuses = async () => {
            const response = await axios.get(ApiRoutes.productOutputs.statuses);

            statuses.value = response.data;
        };

        onMounted(() => {
            getStatuses();

            const modal = document.getElementById('kt_modal_search_product_output');

            // if (modal) modal.addEventListener('hidden.bs.modal', function() {
            // });

            if (modal) modal.addEventListener('shown.bs.modal', function() {

                if (props.params) {
                    // Fill Intra fields
                    Object.assign(queryParams.value, props.params);
                }
            });

            jQuery("#productOutputEmployeeSearch").select2({
                dropdownParent: modal,
                minimumInputLength: 1,
                placeholder: translate('search') + '...',
                ajax: {
                    dataType: 'json',
                    delay: 250,
                    url: ApiRoutes.employees.list,
                    data: function (params) {
                        return {
                            q: params.term,
                        };
                    },
                    processResults: function (data, params) {
                        return {
                            results: data
                        };
                    },
                    cache: false
                }

            }).on('select2:select', function(e){
                const employee = e.params.data;
                queryParams.value.employee_id = employee.id;
            });

            jQuery("#productOutputProductList").select2({
                dropdownParent: modal,
                minimumInputLength: 1,
                multiple: true,
                placeholder: translate('search') + '...',
                ajax: {
                    dataType: 'json',
                    delay: 250,
                    url: ApiRoutes.products.list,
                    data: function (params) {
                        return {
                            q: params.term,
                        };
                    },
                    processResults: function (data, params) {
                        return {
                            results: data
                        };
                    },
                    cache: false
                }

            }).on('select2:select', function(e){
                let product = e.params.data;

                queryParams.value.product_ids.push(product.id);
            }).on('select2:unselect', function(e){
                let product = e.params.data;

                const index = queryParams.value.product_ids.indexOf(product.id);

                queryParams.value.product_ids.splice(index, 1);
            });
        });

        return {
            translate,
            queryParams,
            submit,
            statuses,
            submitButtonRef,
            searchProductOutputModalRef,
        };
    },
});
