
import { useI18n } from "vue-i18n/index";
import moment from 'moment';
import axios, { AxiosResponse } from "axios";
import { defineComponent, onMounted, ref } from "vue";
import { Modal } from "bootstrap";
import ApiRoutes from "@/core/config/ApiRoutes";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import EditProductOutputModal from "@/components/modals/referencials/product-outputs/EditProductOutputModal.vue";
import SearchProductOutputModal from "@/components/modals/referencials/product-outputs/SearchProductOutputModal.vue";

interface IPagination {
    page  : number;
    search: boolean;
    exists: number;
    total : number;
    offset: number;
}

export default defineComponent({
    name: "referencials-product-outputs",
    props: {
        widgetClasses: String,
    },
    components: {
        EditProductOutputModal,
        SearchProductOutputModal,
    },
    setup() {
        const { t, te } = useI18n();

        let loading = ref(false);
        let statuses = ref<any>({});
        let activeProductOutput = ref<any>({});

        const productOutputs = ref([]);
        const queryParamsDefault = {
            status       : null,
            date         : null,
            employee_id  : null,
            knownEmployee: true,
            last_name    : "",
            first_name   : "",
            job          : "",
            note         : "",
            products_ids : [],
        };
        const queryParams = ref({});
        Object.assign(queryParams.value, queryParamsDefault);

        const dateFormat = (value) => {
            return moment(value).format('DD-MM-YYYY');
        };

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const queryParamsLabels = {
            status     : translate("productOutputStatusLabel"),
            date       : translate("productOutputDateLabel"),
            employee_id: translate("productOutputEmployeeIdLabel"),
            last_name  : translate("productOutputLastNameLabel"),
            first_name : translate("productOutputFirstNameLabel"),
            job        : translate("productOutputJobLabel"),
            note       : translate("productOutputNoteLabel"),
            product_ids: translate("productOutputProductIdLabel"),
        };
        const pagination = ref<IPagination>({
            page  : 1,
            search: false,
            exists: 0,
            total : 0,
            offset: 10,
        });

        const goPage = (page: number) => {
            pagination.value.page = page;

            getProductOutputs();
        };

        const setOffset = (event) => {
            pagination.value.offset = parseInt(event.target.value);

            getProductOutputs();
        };

        const refresh = () => {
            pagination.value.page = 1;
            pagination.value.total = 0;
            pagination.value.offset = 10;

            getProductOutputs();
        };

        const create = () => {
            activeProductOutput.value = null;
            const modal = new Modal(document.getElementById('kt_modal_edit_product_output'));
            modal.show();
        };

        const edit = (productOutput) => {
            activeProductOutput.value = productOutput;
            const modal = new Modal(document.getElementById('kt_modal_edit_product_output'));
            modal.show();
        };

        const confirmRemove = (pdoruct_output_id: number) => {

            Swal.fire({
                title: translate("warningHeading"),
                text: translate("productOutputConfirmDelete"),
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: translate("confirm"),
                cancelButtonText: translate("cancel"),
                customClass: {
                    confirmButton: "btn btn-danger",
                    cancelButton: "btn btn-secondary",
                },
            }).then((result) => {

                if (result.isConfirmed) {
                    remove(pdoruct_output_id);
                }
            })
        }

        const remove = (pdoruct_output_id: number) => {

            axios.delete(`${ApiRoutes.productOutputs.delete}/${pdoruct_output_id}`)
                .then((response: AxiosResponse) => {

                    if (response.data.success) {

                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            refresh();
                        });

                    } else {

                        Swal.fire({
                            text: response.data.message,
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        const searchProductOutputs = (params) => {

            for (let key in params) {

                if (
                    Array.isArray(params[key]) &&
                    params[key].length > 1 &&
                    moment(params[key][0], "YYYY-MM-DD", true).isValid() &&
                    moment(params[key][1], "YYYY-MM-DD", true).isValid()
                ) {
                    params[key][0] = dateFormat(params[key][0]);
                    params[key][1] = dateFormat(params[key][1]);
                } else {
                    queryParams.value[key] = params[key];
                }
            }

            refresh();
        };

        const removeQueryParam = (name: string): void => {
            if (queryParams.value && typeof queryParams.value[name] !== 'undefined') {
                queryParams.value[name] = queryParamsDefault[name];

                refresh();
            }
        };

        const getStatuses = async () => {
            const response = await axios.get(ApiRoutes.productOutputs.statuses);

            statuses.value = response.data;
        };

        const getProductOutputs = async () => {
            loading.value = true;

            const params = {};
            Object.assign(params, queryParams.value, pagination.value);

            await axios.get(ApiRoutes.productOutputs.index, { params: params })
                .then((response: AxiosResponse) => {

                    loading.value = false;

                    pagination.value.search = response.data.search;
                    pagination.value.exists = response.data.exists;
                    pagination.value.total = response.data.total;

                    productOutputs.value = response.data.rows;
                })
                .catch((error) => {

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        onMounted(async () => {
            await getStatuses();

            getProductOutputs();

            setCurrentPageBreadcrumbs(translate("productOutputHeading"), [
                translate("masterDataHeading"),
                translate("referencialsHeading"),
                translate("stockManagementHeading")
            ]);
        });

        return {
            translate,
            dateFormat,
            productOutputs,
            statuses,
            pagination,
            activeProductOutput,
            queryParams,
            queryParamsDefault,
            queryParamsLabels,
            searchProductOutputs,
            removeQueryParam,
            loading,
            refresh,
            goPage,
            create,
            edit,
            confirmRemove,
            setOffset,
        };
    },
});
