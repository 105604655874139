
import { useI18n } from "vue-i18n/index";
import * as Yup from "yup";
import axios, { AxiosResponse } from "axios";
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { hideModal } from "@/core/helpers/dom";
import ApiRoutes from "@/core/config/ApiRoutes";

import jQuery from 'jquery';
import 'select2/dist/js/select2.full.min';
import 'select2/dist/css/select2.min.css';

export default defineComponent({
    name: "referencials-product-outputs-edit-modal",
    emit: ["refreshData"],
    props: {
        productOutput: null
    },
    components: {
        ErrorMessage,
        Field,
        Form,
    },
    setup(props, { emit }) {
        const { t, te } = useI18n();

        let modal = ref();
        let isUpdate = false as boolean;

        let statuses = ref<any>([]);

        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const editProductOutputModalRef = ref<null | HTMLElement>(null);
        const productOutputDefault = {
            id            : null,
            status        : null,
            datetime      : null,
            employee_id   : null,
            knownEmployee : true,
            last_name     : "",
            first_name    : "",
            job           : "",
            note          : "",
            products_array: [],
        };
        const productOutputData = ref<any>({});
        Object.assign(productOutputData.value, productOutputDefault);

        const translate = (text, args = null) => {
            if (te(text)) {
                return t(text, args);
            } else {
                return text;
            }
        };

        const validationSchema = Yup.object().shape({
            status: Yup.number().required().label(translate("productOutputStatusLabel")),
        });

        const enableSubmitButton = () => {

            if (submitButtonRef.value) {
                submitButtonRef.value.disabled = false;

                submitButtonRef.value?.removeAttribute("data-kt-indicator");
            }
        };

        const disableSubmitButton = () => {
            if (!submitButtonRef.value) {
                return;
            }

            // Disable button
            submitButtonRef.value.disabled = true;
            // Activate indicator
            submitButtonRef.value.setAttribute("data-kt-indicator", "on");
        };

        const getStatuses = async () => {
            const response = await axios.get(ApiRoutes.productOutputs.statuses);

            statuses.value = response.data;
        };

        const submit = (values, actions) => {
            disableSubmitButton();

            if (isUpdate && props.productOutput.id) productOutputData.value.id = props.productOutput.id;

            axios.post(ApiRoutes.productOutputs.updateOrCreate, productOutputData.value)
                .then((response: AxiosResponse) => {

                    enableSubmitButton();

                    if (response.data && response.data.success) {

                        Swal.fire({
                            text: response.data.message,
                            icon: "success",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        }).then(() => {
                            // actions.resetForm();

                            hideModal(editProductOutputModalRef.value);

                            emit("refreshData");
                        });

                    } else if (response.data && response.data.error) {

                        let error = (Array.isArray(response.data.error)) ? response.data.error.join('</li><li>') : response.data.error;

                        Swal.fire({
                            html: '<h3>' + translate("errorHeading") + '</h3><div class="alert alert-danger"><ul class="list-unstyled mb-0"><li>' + error + "</li></ul></div>",
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: translate("confirm"),
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                        });
                    }
                })
                .catch((error) => {

                    enableSubmitButton();

                    Swal.fire({
                        html: '<h3>' + translate("errorHeading") + '</h3><p>' + translate("errorConnectionMessage") + '</p>',
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: translate("confirm"),
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                    });
                });
        };

        const removeProduct = (index: number) => {
            productOutputData.value.products_array.splice(index, 1);
        };

        const toggleKnown = () => {
            productOutputData.value.employee_id = productOutputDefault.employee_id;
        };

        onMounted(() => {
            getStatuses();

            modal.value = document.getElementById('kt_modal_edit_product_output');

            jQuery("#productOutputEmployeeId").select2({
                dropdownParent: modal.value,
                minimumInputLength: 1,
                placeholder: translate('search') + '...',
                ajax: {
                    dataType: 'json',
                    delay: 250,
                    url: ApiRoutes.employees.list,
                    data: function (params) {
                        return {
                            q: params.term,
                        };
                    },
                    processResults: function (data, params) {
                        return {
                            results: data
                        };
                    },
                    cache: false
                }

            }).on('select2:select', function(e){
                const employee = e.params.data;
                productOutputData.value.employee_id = employee.id;
            });

            jQuery("#productOutputProductId").select2({
                dropdownParent: modal.value,
                minimumInputLength: 1,
                placeholder: translate('search') + '...',
                ajax: {
                    dataType: 'json',
                    delay: 250,
                    url: ApiRoutes.products.list,
                    data: function (params) {
                        return {
                            q: params.term,
                            exclude: productOutputData.value.products_array.map(a => a.id),
                        };
                    },
                    processResults: function (data, params) {
                        return {
                            results: data
                        };
                    },
                    cache: false
                }

            }).on('select2:select', function(e){
                let product = e.params.data;
                let data = {
                    id          : product.id,
                    variant_id  : null,
                    has_variants: product.has_variants,
                    name        : product.name,
                    operation   : 'decrease',
                    quantity    : null,
                };

                if (product.has_variants) {

                    product.variants.forEach((variant) => {

                        productOutputData.value.products_array.push(Object.assign({}, data, {name: `${product.name} - ${variant.name}`, variant_id: variant.id}));
                    });

                } else {
                    productOutputData.value.products_array.push(data);
                }

                jQuery("#productOutputProductId").val('').trigger('change');
            });

            if (modal.value) modal.value.addEventListener('shown.bs.modal', async function() {
                isUpdate = false;

                if (props.productOutput && props.productOutput.id) {
                    isUpdate = true;

                    // Fill productOutput fields
                    Object.keys(props.productOutput).forEach((key) => {
                        if (typeof productOutputDefault[key] == 'boolean') productOutputData.value[key] = Boolean(props.productOutput[key]);
                        else productOutputData.value[key] = props.productOutput[key];
                    });

                } else {
                    Object.assign(productOutputData.value, productOutputDefault);

                    productOutputData.value.products_array.length = 0;
                }
            });
        });

        return {
            translate,
            modal,
            statuses,
            toggleKnown,
            ApiRoutes,
            removeProduct,
            productOutputData,
            validationSchema,
            submit,
            submitButtonRef,
            editProductOutputModalRef,
        };
    },
});
